import React, { useRef, useState, useEffect } from 'react';
import backgroundSrc from './background.jpg';
import Video from './video.mp4';
import {
  FaFacebookSquare,
  // FaInstagram,
  FaLinkedin,
  // FaYoutube,
  // FaTwitch,
} from 'react-icons/fa';
// import { IoMdRefresh } from 'react-icons/io';

import './App.css';

import { ReactComponent as Logo } from './logo.svg';
import { ReactComponent as GmailLogo } from './gmail.svg';
import { ReactComponent as YoutubeLogo } from './youtube.svg';
import { ReactComponent as InstagramLogo } from './instagram.svg';

export default function App() {
  const vidRef = useRef(null);

  const [videoTime, setVideoTime] = useState(0);

  useEffect(() => {
    if (vidRef.current) {
      const handleTimeUpdate = (e) => {
        setVideoTime(e.timeStamp / 1000);
      };

      vidRef.current.addEventListener('timeupdate', handleTimeUpdate);

      // return () => {
      //   vidRef.current.removeEventListener(
      //     'timeupdate',
      //     handleTimeUpdate,
      //   );
      // };
    }
  }, []);

  // useEffect(() => {
  //   console.log(videoTime)
  // }, [videoTime])

  return (
    <div className="App">
      <Logo className={videoTime > 4 ? 'logo show' : 'logo hide'} />
      <div className={videoTime > 5 ? 'icons show' : 'icons hide'}>
        <a href="mailto:josh@flyav8r.aero" title="Email">
          <GmailLogo />
        </a>
        <a
          href="https://youtube.com/flyav8r"
          title="YouTube"
          target="_blank"
          rel="noopener noreferrer"
        >
          <YoutubeLogo />
        </a>
        <a
          href="https://instagram.com/flyav8r"
          title="Instagram"
          target="_blank"
          rel="noopener noreferrer"
        >
          <InstagramLogo />
        </a>
        <a
          href="https://facebook.com/flyav8r"
          title="Facebook"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaFacebookSquare style={{ color: '#4267B2' }} />
        </a>
        <a
          href="https://linkedin.com/in/flyav8r"
          title="LinkedIn"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaLinkedin style={{ color: '#0e76a8' }} />
        </a>
        {/* <a href="https://twitch.com/flyav8r" title="Twitch">
            <FaTwitch style={{ color: '#6441a5' }} />
          </a> */}
      </div>
      {/* <IoMdRefresh
          className="refresh"
          onClick={() => {
            window.location.reload();
          }}
          style={{ color: '#ffffff' }}
        /> */}
      <img
        className={
          videoTime > 3 ? 'background show' : 'background hide'
        }
        src={backgroundSrc}
        alt=""
      ></img>
      <video
        ref={vidRef}
        className={videoTime < 4 ? 'video show' : 'video hide'}
        muted
        autoPlay
        src={Video}
        type="video/mp4"
      />
    </div>
  );
}
